import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TranslateService {
    data: any = {};
    currentLang = null;

    constructor(
      private http: HttpClient
    ) { }

    use(lang: string): Promise<{}> {
        return new Promise<{}>((resolve, reject) => {
            const langPath = `assets/languages/${lang || 'de'}.json`;

            this.http.get<{}>(langPath).subscribe(
                translation => {
                    this.currentLang = lang;
                    this.data = Object.assign({}, translation || {});
                    resolve(this.data);
                },
                error => {
                    this.currentLang = null;
                    this.data = {};
                    resolve(this.data);
                }
            );
        });
    }
}
