import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Osteopathie Weinstrasse';

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.addSvg(faSyncAlt);
    this.addSvg(faFacebookF);
    this.addSvg(faInstagram);
  }

  // https://github.com/angular/material2/issues/11630
  addSvg(icon: IconDefinition) {
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox = "0 0 ${icon.icon[0]} ${icon.icon[1]}" > <path d="${icon.icon[4]}" /> </svg>;`;

    this.iconRegistry.addSvgIconLiteralInNamespace(
      icon.prefix,
      icon.iconName,
      this.sanitizer.bypassSecurityTrustHtml(svg)
    );
  }
}
