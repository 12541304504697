import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-praxis',
  template: `
    <app-hero
      [title]="'hero.praxis.header' | translate | uppercase"
      [url]="'hero.praxis.url' | translate"
    ></app-hero>

    <app-nextpage
      [title]="'hero.oliver.header' | translate | uppercase"
      [subTitle]="'readmore' | translate | uppercase"
      [link]="'/oliver'"
    ></app-nextpage>
  `,
  styles: []
})
export class PraxisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
