import { WINDOW } from '@ng-toolkit/universal';
import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-headbar',
  templateUrl: './headbar.component.html',
  styleUrls: ['./headbar.component.scss']
})
export class HeadbarComponent {

  public scrollY = 0;

  @HostListener('window:scroll', ['$event'])
  onScroll(ev) {
    this.scrollY = window.scrollY;
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    public router: Router
  ) { }

}
