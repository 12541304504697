import { Component, OnInit } from '@angular/core';
import { text } from '@fortawesome/fontawesome-svg-core';

interface Partner {
  title: string,
  text: string,
  link: string,
  href: string
};

@Component({
  selector: 'app-partner',
  template: `
    <app-hero [title]="'Partner' | uppercase"></app-hero>
    <div class="container">
      <div class="row mt-3">
        <div *ngFor="let item of partner" class="col-12 col-lg-6 col-xl-4 text-left pb-3">
          <h2 [innerHTML]="item.title" class="h5"></h2>
          <p [innerHTML]="item.text"></p>
          <a target="_blank" rel="nofollow" href="{{item.href}}">{{item.link}}</a>
        </div>
      </div>
    </div>
  `,
  styles: []
})

export class PartnerComponent implements OnInit {

  public partner: Partner[] = [
    {
      title: 'Simone Decker',
      text: 'BabySteps<br>Trageberatung<br>MAWIBA-Mama-Baby-Tanz<br>Usw.',
      link: 'yaosha.de',
      href: 'http://yaosha.de'
    },
    {
      title: 'Sterntaler Kinderhospiz',
      text: 'Sowohl Häusliche als auch Stationäre Intensivpflege',
      link: 'kinderhospiz-sterntaler.de',
      href: 'https://www.kinderhospiz-sterntaler.de'
    },
    {
      title: 'Britta Salat',
      text: 'Babys in Bewegung<br>Babymassage<br>Beckenbodengymnastik<br>Usw.',
      link: 'salat-fitness.de',
      href: 'https://www.salat-fitness.de'
    },
    {
      title: 'Daniela Götz',
      text: 'Trageberatung',
      link: 'deine-trageberatung.de',
      href: 'http://www.deine-trageberatung.com'
    },
    {
      title: 'Lisa Maria Bruckner',
      text: 'Trageberatung',
      link: 'glucksband.net',
      href: 'http://glucksband.net'
    },
    {
      title: 'Mumpitz<br>Susanne Kimmel',
      text: 'Babysachen selbstgenäht',
      link: 'facebook.com/babysachenmumpitz',
      href: 'https://de-de.facebook.com/babysachenmumpitz/'
    },
    {
      title: 'Kinderorthopädie<br>Prof.Dr.med.Ute Schreiner',
      text: 'Kinderorthopädie im St.Marienkrankenhaus Ludwigshafen',
      link: 'st-marienkrankenhaus.de',
      href: 'https://www.st-marienkrankenhaus.de/kliniken-zentren/schmerzen-unfall-chirurgie/orthopaedische-und-unfallchirurgische-klinik/leistungen-schwerpunkte/kinderorthopaedie/'
    },
    {
      title: 'Kinderchirurgie<br>Dr.med.Maria F. Roll',
      text: 'Kinderchirurgie im St.Marienkrankenhaus Ludwigshafen',
      link: 'st-marienkrankenhaus.de',
      href: 'https://www.st-marienkrankenhaus.de/kliniken-zentren/geburt-kinder-frauen/kinderchirurgie/'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
