import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oliver',
  templateUrl: './oliver.component.html',
  styleUrls: ['./oliver.component.scss']
})
export class OliverComponent implements OnInit {

  public text = [
    [
      "Osteopathie ist für mich, den Menschen in seiner Gesamtheit zu betrachten und nicht nur die Symptome, sondern auch die Ursachen funktioneller Störungen zu erkennen und zu behandeln. Dabei kann das Vernetzen mit anderen Berufsgruppen sehr nützlich für den Patienten sein."
    ]
  ];

  constructor() { }

  ngOnInit() {
  }

}
