import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartseiteComponent } from './component/startseite/startseite.component';
import { DatenschutzComponent } from './component/datenschutz/datenschutz.component';
import { ImpressumComponent } from './component/impressum/impressum.component';
import { BehandlungComponent } from './component/behandlung/behandlung.component';
import { PraxisComponent } from './component/praxis/praxis.component';
import { ZeitbedarfKostenComponent } from './component/zeitbedarf-kosten/zeitbedarf-kosten.component';
import { OliverComponent } from './component/oliver/oliver.component';
import { PartnerComponent } from './component/partner/partner.component';
import { HinweiseComponent } from './component/hinweise/hinweise.component';
import { KontaktComponent } from './component/kontakt/kontakt.component';

const routes: Routes = [
  { path: '', component: OliverComponent },
  { path: 'behandlung', component: BehandlungComponent },
  { path: 'zeitbedarf', component: ZeitbedarfKostenComponent },
  { path: 'kontakt', component: KontaktComponent },
  { path: 'hinweise', component: HinweiseComponent },
  { path: 'datenschutz', component: DatenschutzComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
