import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-datenschutz',
    template: `
    <app-hero
      [title]="'Datenschutz' | uppercase"
    ></app-hero>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p>Die Betreiber dieser Seiten nehmen
              den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
              vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
              Datenschutzerklärung.</p>
          <p>Die Nutzung unserer Website ist in der Regel ohne Angabe
              personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten
              (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit
              möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
              Zustimmung
              nicht an Dritte weitergegeben.</p>
          <p>Wir weisen darauf hin, dass die Datenübertragung im Internet
              (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser
              Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
          <br>
          <h2 class="h5">Cookies</h2>
          <p>Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf
              Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot
              nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf
              Ihrem
              Rechner abgelegt werden und die Ihr Browser speichert.</p>
          <p>Die meisten der von uns verwendeten
              Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs automatisch
              gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen.
              Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch
              wiederzuerkennen.</p>
          <p>Sie können Ihren Browser so einstellen, dass Sie über das Setzen
              von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für
              bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der
              Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die
              Funktionalität dieser Website eingeschränkt sein.</p>
          <br>
          <h2 class="h5">Google Analytics</h2>
          <p>Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter
              ist die Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.</p>
          <p>Google
              Analytics verwendet so genannte "Cookies". Das sind Textdateien, die auf Ihrem Computer
              gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die
              durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel
              an einen Server von Google in den USA übertragen und dort gespeichert.</p>
          <br>
          <h3 class="h6">IP Anonymisierung</h3>
          <p>Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert.
              Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union
              oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor
              der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse
              an
              einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers
              dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten,
              um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der
              Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem
              Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser
              übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.</p>
          <br>
          <h3 class="h6">Browser Plugin</h3>
          <p>Sie können die Speicherung der Cookies durch eine
              entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass
              Sie
              in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich
              werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie
              erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie
              die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link
              verfügbare Browser-Plugin herunterladen und installieren: <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a></p>
          <br>
          <h3 class="h6">Widerspruch gegen Datenerfassung</h3>
          <p>Sie können die Erfassung Ihrer Daten durch Google Analytics
              verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die
              Erfassung
              Ihrer Daten bei zukünftigen Besuchen dieser Website verhindert: <a href="javascript:gaOptout();">Google Analytics deaktivieren</a></p>
          <p>Mehr Informationen zum
              Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google:
              <a href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank">https://support.google.com/analytics/answer/6004245?hl=de</a></p>
          <br>
          <h3 class="h6">Auftragsdatenverarbeitung</h3>
          <p>Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die
              strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics
              vollständig um.</p>
          <br>
          <h3 class="h6">Demografische Merkmale bei Google Analytics</h3>
          <p>Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics.
              Dadurch können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der
              Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie
              aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet
              werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem
              Google-
              Konto deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics wie im Punkt
              “Widerspruch gegen Datenerfassung” dargestellt generell untersagen.
          </p>
          <br>
          <p>Quelle: <a href="https://www.e-recht24.de/muster-datenschutzerklaerung.html">https://www.erecht24.
                  de/muster-datenschutzerklaerung.html</a></p>
        </div>
      </div>
    </div>
  `,
    styles: []
})
export class DatenschutzComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
