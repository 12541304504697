import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  private path: string = '/sendmail';

  constructor(
    private http: HttpClient
  ) { }

  sendMail(email) {
    return this.http.post(this.path, email);
  }
}
