import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image',
  template: `
  <div class="container">
    <div class="row align-content-center justify-content-center">
      <div class="col-12">
        <div class="embed-responsive embed-responsive-16by9">
          <div class="embed-responsive-item">
            <img class="img-fluid" [src]="url" [alt]="alt">
          </div>
        </div>
      </div>
    </div>
  </div>
  `,
  styles: [ '.img-fluid { transform: translate(-50%, -50%); position: absolute; top: 50%; left: 50%; }' ]
})
export class ImageComponent {

  @Input() url: string;
  @Input() alt?: string = '';

  constructor() { }

}
