import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { MenustateService } from '../../service/menustate.service';
import { MathService } from '../../service/math.service';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent {

  // @ViewChild('wrapper') el:ElementRef;

  @Input() title?: string;
  @Input() titleSize?: string = 'h2';
  @Input() titleAlignment?: string = 'left';

  @Input() upperSubTitle?: string;
  @Input() upperSubTitleSize?: string = 'h3';
  @Input() upperSubTitleAlignment?: string = 'left';

  @Input() bg?: string = 'white';
  @Input() color?: string = 'black';

  @Input() upperBodyText?: Array<string>;
  @Input() upperBodyTextAsList = false;
  @Input() upperBodyTextBold = false;
  @Input() upperBodyTextAlignment?: string = "left";

  @Input() underBodyText?: Array<string>;
  @Input() underBodyTextAsList = false;
  @Input() underBodyTextBold = false;
  @Input() underBodyTextAlignment?: string = "left";

  @Input() badgeText?: string;
  @Input() badgeLink?: string;

  @Input() spaceles?: boolean = false;
  @Input() specialElement?: string = 'default';

  public active: boolean = false;

  constructor() { }
}
