import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-behandlung',
  template: `
    <app-hero
      [title]="'Behandlung' | uppercase"
      [url]="'/assets/images/osteopathie_weinstrasse_7078.jpg'"
    ></app-hero>

    <app-text
      [bg]="'green'"
      [color]="'white'"
      [title]="'DIE OSTEOPATHISCHE UNTERSUCHUNG UND BEHANDLUNG IST EINE FORM DER MEDIZIN DIE AUSSCHLIESSLICH MANUELL, DAS HEISST MIT DEN HÄNDEN ERFOLGT.' | uppercase"
      [titleAlignment]="'center'"
      [titleSize]="'h5'"
    ></app-text>

    <div class="container">
      <div class="row no-gutters justify-content-center">
        <div class="col-12 col-md-10">
          <app-text
            [bg]="'white'"
            [underBodyText]="['Dabei ertastet der Osteopath Bewegungseinschränkungen und veränderte Spannungen in folgenden Bereichen']"
          ></app-text>
        </div>
      </div>
    </div>

    <app-system-block></app-system-block>
    
    <div class="container">
      <div class="row no-gutters justify-content-center">
        <div class="col-12 col-md-10">
          <app-text
            [bg]="'white'"
            [underBodyText]="['Gesucht wird nach der ursächlichen Störung , die für die Beschwerden (mit-)verantwortlich ist. Dabei werden die gegenseitige Beeinflussung von Bewegungsapparat, Organen und dem Cranio-Sacralensystem berücksichtigt.']"
          ></app-text>
        </div>
      </div>
    </div>

    <app-image
      [url]="'/assets/images/osteopathie_weinstrasse_8576.jpg'"
    ></app-image>
    
    <div class="container">
      <div class="row no-gutters justify-content-center">
        <div class="col-12 col-md-10">
          <app-text
            [bg]="'white'"
            [underBodyText]="['Diese funktionellen Störungen beruhen meistens auf einer verminderten Beweglichkeit und/oder vermehrten Spannung. Die osteopathische Behandlung hilft dabei, dem Gewebe möglichst wieder die „ursprüngliche“ Beweglichkeit zurückzugeben. Und die Kette an Folgestörungen abzubauen.']"
          ></app-text>
        </div>
      </div>
    </div>

    <div class="pb-3 pb-sm-4">
      <app-image
        [url]="'/assets/images/osteopathie_weinstrasse_7259.jpg'"
      ></app-image>
    </div>

    <div class="py-sm-4 py-3 px-2 text-black">
      <div class="container">
        <div class="row align-content-center justify-content-center">
          <div class="col-12 text-left pb-2">
            <h2 class="m-0 h3">
              {{'Was Müssen Sie mitbringen?' | uppercase}}
            </h2>
          </div>

          <div class="col-12 text-left">
            <p class="pb-1">
              Erwachsene Patienten benötigen ein <strong>großes Handtuch</strong> zum Unterlegen. Gibt es aktuelle Befunde dürfen Sie diese gerne mitbringen, oder auch z.B. den Mutterpass.
            </p>
            <p class="pb-1">
              Bei allen kleinen Hauptpersonen benötigen wir auch ein <strong>großes Handtuch</strong> und das Gelbe Untersuchungsheft. Gerne können Sie auch Snacks, was zu Trinken oder das Lieblingsbuch/Hörbuch mitbringen. 
            </p>
          </div>
        </div>
      </div>
    </div>

    <app-nextpage
      [title]="'zeitbedarf & Kosten' | uppercase"
      [subTitle]="'Erfahren Sie mehr über' | uppercase"
      [link]="'/zeitbedarf'"
    ></app-nextpage>

    <div class="py-sm-4 py-3 px-2 bg-yellow text-black">
      <div class="container">
        <div class="row align-content-center justify-content-center">
          <div class="col-12 text-left pb-2">
            <h2 class="m-0 h5">
              {{'Downloads' | uppercase}}
            </h2>
          </div>
    
          <div class="col-12 text-left">
            <a class="text-red" href="/assets/downloads/behandlungsvertrag.pdf" target="_blank">Behandlungsvertrag (PDF)</a>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: []
})
export class BehandlungComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
