import { Component, OnInit } from '@angular/core';
import { MenustateService } from '../../service/menustate.service';

@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss']
})
export class HamburgerComponent implements OnInit {

  constructor(
    public menuStatus: MenustateService
  ) { }

  ngOnInit() {
  }

}
