import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-nextpage',
  templateUrl: './nextpage.component.html',
  styleUrls: ['./nextpage.component.scss']
})
export class NextpageComponent {

  @Input() title: string;
  @Input() subTitle: string;
  @Input() target: string;
  @Input() link: string;

  constructor( ) { }

}
