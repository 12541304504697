import { Component, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-startseite',
  templateUrl: './startseite.component.html',
  styleUrls: ['./startseite.component.scss']
})
export class StartseiteComponent {

  public orientation = 'portrait';
  public bufferOrientation = 'landscape';
  public minHeight: string;

  @Input() title: string;
  @Input() url: string;
  @Input() alt?: string = '';
  @Input() focus?: string = 'center center';

  @HostListener('window:resize', ['$event.target'])
  onResize(win) {
    if (win.orientation !== undefined) {
      this.orientation = win.innerHeight > win.innerWidth ? 'portrait' : 'landscape';
      if (this.bufferOrientation !== this.orientation) {
        this.bufferOrientation = this.orientation;

        switch (this.orientation) {
          case 'portrait':
            this.minHeight = `${win.screen.height}px`;
            break;
          case 'landscape':
            this.minHeight = `${win.screen.width}px`;
            break;
        }
      }
    } else {
      this.minHeight = `${win.innerHeight}px`;
    }
  }

  constructor() {
    this.onResize(window);
  }

}
