import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-impressum',
    template: `
    <app-hero
      [title]="'impressum' | uppercase"
    ></app-hero>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="h5">Angaben gemäß § 5 TMG:</h2>
          <p>OSTEOPATHIE WEINSTRASSE<br>
        in einer Praxisgemeinschaft
        <br><br>
        LOGOSTEGA - Praxis für
        <br>
        Logopädie & Osteopathie
        <br><br>
        Friedrichstraße 46<br>
        67433 Neustadt an der Weinstraße<br><br>
          </p>
          <br>
          <h2 class="h5">Kontakt:</h2>
          <p>
              Telefon: <a href="tel:+4963249369913">06324 93 6 99 13</a><br>
              E-Mail: <a href="mailto:info@osteopathie-weinstrasse.de">info@osteopathie-weinstrasse.de</a>
          </p>
          <br>
          <h2 class="h5">DESIGN, KONZEPT, PROGRAMMIERUNG</h2>
          <p>
              Buero Medienagenten<br>
              Kurgartenstraße 8 • 67098 Bad Dürkheim<br>
              T 06322 91991-0 • F 06322 91991-99<br>
              <a href="mailto:info@medienagenten.de">info@medienagenten.de</a> • <a href="http://www.medienagenten.de" target="_blank">www.medienagenten.de</a>
          </p>
          <br>
          <h2 class="h5">Umsatzsteuer:</h2>
          <p>
              Umsatzsteuer-Identifikationsnummer gemäß §27 a<br>
              Umsatzsteuergesetz: DE 999 999 999
          </p>
          <br>
          <h2 class="h5">Aufsichtsbehörde:</h2>
          <p>
              Gesundheitsamt
              des Landkreises Bad Dürkheim<br>
              Neumayerstraße 10<br>
              67433 Neustadt an der Weinstraße<br>
              <a href="https://www.kreis-bad-duerkheim.de/kv_bad_duerkheim/Bürgerservice/Einträge/?bsinst=0&amp;bstype=a_get&amp;bsparam=263455" target="_blank">⇛ Website</a>
          </p>
          <br>
          <h2 class="h5">Berufsbezeichnung und berufsrechtliche Regelungen</h2>
          <p>
              Berufsbezeichnung: Heilpraktiker<br>
              Zuständige Kammer: <br> Verliehen durch: <br> Es gelten folgende berufsrechtliche
              Regelungen: Berufsbezeichnung: Heilpraktiker, wurde in der Bundesrepublik Deutschland verliehen. Die
              berufsrechtliche Regelung bildet das Gesetz über die berufsmäßige Ausübung der
              Heilkunde ohne Bestallung (Heilpraktikergesetz) vom 17.02.1939, geändert durch Art. 53 des
              EGStGB vom 02.03.1974 (BGBl. I S. 469) und kann unter www.gesetze-im-internet.de unter
              Stichwort/Titelsuche „Heilpraktikergesetz“ eingesehen werden.<br> Regelungen einsehbar
              unter:
              <a href="http://www.gesetze-im-internet.de " target="_blank">http://www.gesetze-im-internet.de</a>
          </p>
          <br>
          <h2 class="h5">Angaben zur Berufshaftpflichtversicherung</h2>
          <p>
              Name und Sitz des Versicherers:<br>
              Die Continentale<br>
              Ruhrallee 92<br>
              44139 Dortmund
          </p>
          <p>
              Geltungsraum der Versicherung: Deutschland
          </p>
          <br>
          <h2 class="h5">Streitschlichtung</h2>
          <p>
              Die Europäische Kommission stellt eine Plattform
              zur Online-Streitbeilegung (OS) bereit:
              <a href="https://ec.europa.eu/consumers/odr" target="_blank">https://ec.europa.eu/consumers/odr</a>
              <br>
              Unsere E-Mail-Adresse finden Sie oben im
              Impressum.
          </p>
          <p>
              Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
              Verbraucherschlichtungsstelle teilzunehmen.
          </p>
          <br>
          <h2 class="h5">Haftung für Inhalte</h2>
          <p>
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
              Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
              Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen
              zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
              hinweisen.
          </p>
          <p>
              Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen
              Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
              Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
              entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
          </p>
          <br>
          <h2 class="h5">Haftung für Links</h2>
          <p>
              Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss
              haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
              Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
              Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
              Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar.
          </p>
          <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
              einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
              Links umgehend entfernen.
          </p>
          <br>
          <h2 class="h5">Urheberrecht</h2>
          <p>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
              deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung
              des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
              privaten, nicht kommerziellen Gebrauch gestattet.
          </p>
          <p>
              Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
              Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
              trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
              Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
              entfernen.
          </p>
          <br>
          <p>Quelle: <a href="https://www.erecht24.de">https://www.e-recht24.de</a></p>
        </div>
      </div>
    </div>

  `,
    styles: []
})
export class ImpressumComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
