import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-system-block',
  templateUrl: './system-block.component.html',
  styleUrls: ['./system-block.component.scss']
})
export class SystemBlockComponent implements OnInit {

  public content = [
    {
      "header": "PARIETALES<br>SYSTEM",
      "upperSubHeader": "Bewegungsapparat",
      "underBodyText": "Die Parietale Osteopathie ist der Bereich osteopathischer Behandlung, der sich mit den Faszien, Muskeln, Knochen und Gelenken des Körpers befasst."
    },
    {
      "header": "VISZERALES<br>SYSTEM",
      "upperSubHeader": "Organssystem",
      "underBodyText": "Die Viszerale Osteopathie befasst sich mit der Behandlung der inneren Organe. Die Organe werden direkt, sanft und oft mit schnellem Erfolg behandelt. Es wird dabei die Beweglichkeit der Organe, die in der Viszeralen Osteopathie Mobilität genannt wird, zueinander und zu dem sie einhüllenden oder stützenden Muskel- und Bindegewebe getastet und gefördert."
    },
    {
      "header": "CRANIO-<br>SACRALES-<br>SYSTEM",
      "upperSubHeader": "Schädel, Kreuzbein",
      "underBodyText": "Die Craniosacral Therapie (lateinisch „cranium“ = Schädel, „sacrum“ = Kreuzbein) ist ein manuelles Verfahren, bei dem der Therapeut rhythmische Bewegungen der Hirn- und Rückenmarksflüssigkeit (Cerebrospinalflüssigkeit oder Liquor) vor allem am Schädel und Kreuzbein des Patienten wahrnimmt und als Grundlage für die Behandlung nutzt."
    }
  ]

  constructor() { }

  ngOnInit() {
  }

}
