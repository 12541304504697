import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenustateService {

  private _status: boolean;

  public statusSubject: Subject<boolean> = new Subject<boolean>();

  constructor()  {
      this.statusSubject.subscribe( value => {
          this._status = value
      });
  }

  get status(): boolean {
    return this._status;
  }

  public trigger() {
    this.statusSubject.next(!this._status);
  }
}
