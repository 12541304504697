import { Component, OnInit } from '@angular/core';
import { MenustateService } from '../../service/menustate.service';

@Component({
  selector: 'app-overlay-menu',
  templateUrl: './overlay-menu.component.html',
  styleUrls: ['./overlay-menu.component.scss']
})
export class OverlayMenuComponent {

  private menuItems: Array<string> = ['behandlung', 'zeitbedarf', 'hinweise', 'kontakt'];
  private menuSubItems: Array<string> = ['impressum', 'datenschutz'];

  public menu = {
    "behandlung": "Behandlung",
    "praxis": "Praxis",
    "zeitbedarf": "Zeitbedarf & Kosten",
    "oliver": "Oliver Hertel",
    "hinweise": "Hinweise",
    "kontakt": "Kontakt",
    "impressum": "Impressum",
    "datenschutz": "Datenschutz"
  };

  constructor(
    public menuStatus: MenustateService
  ) { }
}
