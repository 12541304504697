import { NgtUniversalModule } from '@ng-toolkit/universal';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartseiteComponent } from './component/startseite/startseite.component';
import { BehandlungComponent } from './component/behandlung/behandlung.component';
import { ZeitbedarfKostenComponent } from './component/zeitbedarf-kosten/zeitbedarf-kosten.component';
import { PraxisComponent } from './component/praxis/praxis.component';
import { OliverComponent } from './component/oliver/oliver.component';
import { FooterComponent } from './common/footer/footer.component';
import { OverlayMenuComponent } from './common/overlay-menu/overlay-menu.component';
import { TextComponent } from './common/text/text.component';
import { HinweiseComponent } from './component/hinweise/hinweise.component';
import { HeroComponent } from './common/hero/hero.component';
import { LogoComponent } from './common/logo/logo.component';
import { TrainComponent } from './common/train/train.component';
import { HamburgerComponent } from './common/hamburger/hamburger.component';
import { NextpageComponent } from './common/nextpage/nextpage.component';
import { TranslatePipe } from './pipe/translate.pipe';
import { TranslateService } from './service/translate.service';
import { ImpressumComponent } from './component/impressum/impressum.component';
import { DatenschutzComponent } from './component/datenschutz/datenschutz.component';
import { PartnerComponent } from './component/partner/partner.component';
import { ImageComponent } from './common/image/image.component';
import { HeadbarComponent } from './common/headbar/headbar.component';
import { SystemBlockComponent } from './common/system-block/system-block.component';
import { KontaktComponent } from './component/kontakt/kontakt.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatButtonModule, MatInputModule, MatIconModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieLawModule } from 'angular2-cookie-law';

export function setupTranslateFactory(service: TranslateService): Function {
  return () => service.use('de');
}

@NgModule({
  declarations: [
    AppComponent,
    StartseiteComponent,
    BehandlungComponent,
    ZeitbedarfKostenComponent,
    PraxisComponent,
    OliverComponent,
    FooterComponent,
    OverlayMenuComponent,
    TextComponent,
    HinweiseComponent,
    HeroComponent,
    LogoComponent,
    TrainComponent,
    HamburgerComponent,
    NextpageComponent,
    TranslatePipe,
    ImpressumComponent,
    DatenschutzComponent,
    PartnerComponent,
    KontaktComponent,
    ImageComponent,
    HeadbarComponent,
    SystemBlockComponent,
  ],
  imports: [
    NgtUniversalModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    AppRoutingModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    BrowserAnimationsModule,
    CookieLawModule // import Angular's CookieLaw modules
  ],
  providers: [
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
