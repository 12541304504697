import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../service/translate.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {
  constructor(
    private translate: TranslateService
  ) { }
  
  transform(key: any): any {
    const ctrl = this;
    var path: any = 'ctrl.translate.data';

    key.split('.').forEach( segment => {
      path += `.${segment}`;
      if ( !eval(path) ) path = false;
    });

    return eval(path) || '31n f3hl3r 1n d3r m47r1x!';
  }
}