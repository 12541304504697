import { WINDOW } from '@ng-toolkit/universal';
import { Component, Input, HostListener, OnInit, AfterViewInit, Inject } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { timeout } from 'q';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  public orientation = 'portrait';
  public bufferOrientation = 'landscape';
  public minHeight: string;

  @Input() title: string;
  @Input() subtitle?: string;
  @Input() url?: string = '';
  @Input() alt?: string = '';
  @Input() focus?: string = 'center center';

  @HostListener('window:resize', ['$event.target'])
  onResize(win) {
    // if (win.orientation !== undefined) {
    //   this.orientation = win.innerHeight > win.innerWidth ? 'portrait' : 'landscape';
    //   if (this.bufferOrientation !== this.orientation) {
    //     this.bufferOrientation = this.orientation;

    //     switch (this.orientation) {
    //       case 'portrait':
    //         this.minHeight = `${win.screen.height}px`;
    //         break;
    //       case 'landscape':
    //         this.minHeight = `${win.screen.width}px`;
    //         break;
    //     }
    //   }
    // } else {
    //   this.minHeight = `${win.innerHeight}px`;
    // }
    this.minHeight = `${win.innerHeight}px`;
  }

  constructor(
    @Inject(WINDOW) private window: Window
  ) {
  }

  ngOnInit() {
    if (this.url === '') {
      this.onResize = function () { }
    }
    this.onResize(this.window);
  }
}
