import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zeitbedarf-kosten',
  template: `
    <app-hero
      [title]="'Zeitbedarf & Kosten' | uppercase"
      [url]="'/assets/images/osteopathie_weinstrasse_7294.jpg'"
    ></app-hero>

    <app-text
      [bg]="'green'"
      [color]="'white'"
      [title]="'ZEITBEDARF' | uppercase"
      [titleAlignment]="'left'"
      [titleSize]="'h5'"
      [underBodyText]="[
        'Eine osteopathische Behandlung dauert in der Regel ca. 50-60 Minuten. Gerade Anamnese und Erstbefund bzw. Befundskontrolle benötigen Zeit, die ich mir für Sie auch nehmen will. Der Abstand zwischen den einzelnen Behandlungen kann – insbesondere bei chronischen Problemen – zwei, drei oder mehr Wochen betragen, da der Körper in dieser Zeit noch auf die letzte Behandlung reagieren kann.'
      ]"
    ></app-text>

    <div class="py-sm-4 py-3 px-2 bg-blue text-white">
      <div class="container">
        <div class="row align-content-center justify-content-center">
          <div class="col-12 text-left pb-2">
            <h2 class="m-0 h5">
              {{'Kosten' | uppercase}}
            </h2>
          </div>
    
          <div class="col-12 text-left">
            <p class="pb-1">
            Osteopathie ist eine Privatleistung. Für meine Leistung stelle ich ein Honorar, das sich möglichst an dem Gebührenverzeichnis für Heilpraktiker (GebüH) orientiert, jedoch nicht an dieses gebunden ist.
            Die Behandlungskosten sind nach jeder Therapiesitzung direkt im Anschluss per EC-Karte zu begleichen.
            </p>
            <p class="pb-1">
            Einige private und gesetzliche Krankenkassen beteiligen sich anteilig an den Kosten für Osteopathie.
            Bitte informieren Sie sich vor Behandlungsbeginn bei Ihrer Krankenkasse über die jeweiligen Bezuschussungen und Bedingungen.
            </p>
          </div>
        </div>
      </div>
    </div>

      <app-image
        [url]="'/assets/images/osteopathie_weinstrasse_8634.jpg'"
      ></app-image><div class="pt-3 pt-sm-4">
    
    </div>
  `,
  styles: []
})
export class ZeitbedarfKostenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
