import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MailService } from '../../service/mail.service';

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.scss']
})
export class KontaktComponent implements OnInit {

  public createForm: FormGroup;
  public status = '';

  constructor(
    public fb: FormBuilder,
    public mailer: MailService
  ) {
    this.createForm = this.fb.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      street: ['', Validators.required],
      zip: ['', Validators.required],
      city: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      message: ['', Validators.required]
    });
  }

  public sendMail() {
    this.mailer.sendMail(this.createForm.value)
      .subscribe((request: any) => {
        this.createForm.reset();
        this.status = request.message;
      });
  }

  ngOnInit() {
  }

}
